/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { FiMapPin } from "react-icons/fi";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";

const Map = () => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full mt-[60px]">
        {
          rpdata?.labels?.general?.titleMap ?
            <h2 className="text-center pb-5">
              {rpdata?.labels?.general?.titleMap}
            </h2>
            :
            <h2 className="text-center pb-5">
              We Cover {rpdata?.dbPrincipal?.miles} Miles Around {rpdata?.dbPrincipal?.location?.[0].address}
            </h2>
        }
        {
          rpdata?.dbPrincipal?.location.length > 2 ?
            <ul className="pt-4 pb-4 md:p-0 flex flex-wrap justify-between md:justify-center items-center ">
              {rpdata?.dbPrincipal?.location?.map((item, index) => {
                return (
                  <Link to="/contact" key={index}>
                    <li className="py-2 px-3 flex items-center">
                      <FiMapPin fontSize={25} />
                      <span className="pl-2">{item.address}</span>
                    </li>
                  </Link>
                );
              })}
            </ul>
            : null
        }
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7432.000873358741!2d-86.5917124954212!3d34.534241433977975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886274bde14e7989%3A0x831065703214f802!2sLacey&#39;s%20Spring%2C%20Alabama%2035754%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sni!4v1674833074019!5m2!1ses-419!2sni" className="w-full h-[500px]" />
      </div>
    </div>
  )
}

export default Map
